import { Button, Divider, Form, InputNumber, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";

export default function ProductSize({ productList, setProductList, setActiveButton }) {
  const { token } = useSelector((state) => state.auth);
  const [form] = useForm();

  const sendProductDetails = async (values) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("action", "AddSize");
      formDataToSend.append("token", token);
      formDataToSend.append("product_id", productList.productId);

      // Append individual size fields
      formDataToSend.append("width", values.width);
      formDataToSend.append("height", values.height);
      formDataToSend.append("weight", values.weight);
      formDataToSend.append("length", values.length);

      // Make the POST request
      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formDataToSend
      );

      console.log("API response:", response.data);

      setProductList((prev) => ({
        ...prev,
        size: {
          width: values.width,
          height: values.height,
          weight: values.weight,
          length: values.length,
        },
      }));

      form.resetFields(); // Reset the form after submission
      setActiveButton("color"); // Move to the next step
    } catch (error) {
      console.error("Error sending product details to the API:", error);
    }
  };

  return (
    <>
      <Form
        form={form}
        onFinish={sendProductDetails}
        layout="vertical"
        style={{ marginTop: "20px", padding: "20px", background: "#fff" }}
      >
        <Divider />
        <h5>Add Product Size</h5>
        <Space>
          <Form.Item
            name="width"
            rules={[{ required: true, message: "Width is required" }]}
          >
            <InputNumber
              placeholder="Width"
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>
          <Form.Item
            name="height"
            rules={[{ required: true, message: "Height is required" }]}
          >
            <InputNumber
              placeholder="Height"
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>
          <Form.Item
            name="length"
            rules={[{ required: true, message: "Length is required" }]}
          >
            <InputNumber
              placeholder="Length"
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>
          <Form.Item
            name="weight"
            rules={[{ required: true, message: "Weight is required" }]}
          >
            <InputNumber
              placeholder="Weight"
              style={{ width: "100%" }}
              min={0}
            />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ background: "green", color: "#fff" }}
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Space>

        <Form.Item className="mt-4">
          <Button
            onClick={() => setActiveButton("product")}
            style={{ color: "#fff", background: "#000" }}
          >
            Previous
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
