import {
  Button,
  Form,
  Upload,
  Image,
} from "antd";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function ProductImage({
  productList,
  setProductList,
  setActiveButton,
}) {
  const { token } = useSelector((state) => state.auth);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [form] = Form.useForm();
  const formRef = useRef();
  const mainproductlist = productList.productId;

  const sendColorDetails = async () => {
    try {
      const value = formRef.current.getFieldsValue()
      const formDataToSend = new FormData();
      formDataToSend.append("action", "addImage");
      formDataToSend.append("token", token);
      formDataToSend.append("product_image_type", "demo");
      formDataToSend.append("product_id", mainproductlist);
      formDataToSend.append("product_color_id_refff", mainproductlist);
      
      // Handle image upload properly
      fileList.forEach((file, index) => {
        formDataToSend.append(`product_image[]`, file.originFileObj);
      });
  
      const response = await axios.post(
        "https://jutiepie.in/api/product.php",
        formDataToSend
      );
  
      console.log("API response:", response.data);
      notification.success({
        message: 'Upload Successful',
        description: 'Your product images have been successfully uploaded.',
      });
      // Reset form fields upon successful API call
      setActiveButton("image");
    } catch (error) {
      console.error("Error sending product color to the API:", error);
      notification.error({
        message: 'Upload Failed',
        description: 'There was an error uploading the product images. Please try again.',
      });
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <div>
      <Form onFinish={sendColorDetails} form={form} ref={formRef}>
        <Form.Item
          label="Upload Image"
          valuePropName="fileList"
          name="product_image"
        >
          <Upload
            action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            multiple
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
          {previewImage && (
            <Image
              wrapperStyle={{
                display: 'none',
              }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
            />
          )}
        </Form.Item>

        <Form.Item>
          <Button
            onClick={() => setActiveButton("color")}
            style={{ color: "#fff", background: "#000" }}
          >
            Previous
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            disabled={fileList?.length === 0}
            style={{ color: "#fff", background: "green" }}
            onClick={sendColorDetails}
          >
            Upload
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
