import { Button, Divider, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { useSelector } from "react-redux";
import axios from "axios";
import { SketchPicker } from "react-color"; // Import SketchPicker from react-color

export default function ProductColor({
  productList,
  setProductList,
  setActiveButton,
}) {
  const [selectedColorCode, setSelectedColorCode] = useState("#000000"); // Default color
  const { token } = useSelector((state) => state.auth);
  const [form] = useForm(); // Use Form instance
  const mainproductlist = productList.productId;

  const handleColorChangeComplete = (color) => {
    setSelectedColorCode(color.hex); // Set the selected color code
    console.log("Selected color code:", color.hex); // Console log the color code
  };

  const handleColorSelectionAndSend = async (values) => {
    const colorData = {
      colorCode: selectedColorCode, // Use dynamically picked color code
      colorName: values.colorname,
      quantity: values.quantity,
      price: values.price,
      sizeId: mainproductlist, // Set sizeId to mainproductlist
    };

    if (mainproductlist) { // Check if mainproductlist exists
      try {
        // Prepare the data to send to the API
        const formDataToSend = new FormData();
        formDataToSend.append("action", "AddColor");
        formDataToSend.append("token", token);
        formDataToSend.append("color_name", colorData.colorName);
        formDataToSend.append("color_code", colorData.colorCode);
        formDataToSend.append("product_qty", colorData.quantity);
        formDataToSend.append("product_feature", "xyz");
        formDataToSend.append("Product_Id", mainproductlist); // Use mainproductlist for Product_Id
        formDataToSend.append("product_price", colorData.price);
        formDataToSend.append("Size_Id", mainproductlist); // Use mainproductlist for Size_Id

        // Send data to the API
        const response = await axios.post(
          "https://jutiepie.in/api/product.php",
          formDataToSend
        );

        console.log("API response for color:", response.data);

        // If API request is successful, move to the next step
        setActiveButton("image");
      } catch (error) {
        console.error("Error sending product color to the API:", error);
      }
    } else {
      console.error("Product ID not available. API call will not be made.");
    }

    form.resetFields(); // Reset form after submission
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleColorSelectionAndSend} // Trigger on color selection and send
        layout="vertical"
        className="mt-5"
        style={{ position: "sticky", bottom: "0", background: "#fff" }}
      >
        <Divider />
        <h5>Add Color</h5>
        <div className="row">
          <div className="col-md-6">
            <Form.Item name="color">
              <SketchPicker
                color={selectedColorCode}
                onChangeComplete={handleColorChangeComplete} // Use onChangeComplete from react-color
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="colorname"
              rules={[{ required: true, message: "Color name is required" }]} >
              <Input
                placeholder="Color name"
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="quantity"
              rules={[{ required: true, message: "Available quantity is required" }]} >
              <Input placeholder="Quantity" style={{ width: "100%" }} min={0} />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item
              name="price"
              rules={[{ required: true, message: "Price is required" }]} >
              <Input placeholder="Price" style={{ width: "100%" }} min={0} />
            </Form.Item>
          </div>
        </div>
        <Form.Item className="mt-4">
          <Button
            onClick={() => setActiveButton("size")}
            style={{ color: "#fff", background: "#000" }}
          >
            Previous
          </Button>
          <Button
            htmlType="submit" // Submit form on "Next" click
            disabled={!selectedColorCode} // Disable if no color is selected
            style={{ color: "#fff", background: "green" }}
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
